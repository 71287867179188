<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      title="新增公会"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="600px"
    >

<a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

    <a-form-item label="公会名称">
      <a-input
        v-decorator="formDecorators.name"
      />
    </a-form-item>

    <a-form-item label="UID">
      <a-input
        v-decorator="formDecorators.uid"
      />
    </a-form-item>
    </a-form>
    </a-modal>
  </div>
</template>

<script>

import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default {
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      SHOW_PARENT,
      list:[]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    formDecorators() {
      return {
        name: [
          "name",
          {
            initialValue: '',
            rules: [{ required: true, message: "请填写角色名称" }],
            validateTrigger: ["blur"],
          },
        ],
        uid: [
          "uid",
          {
            initialValue: '',
            rules: [{ required: true, message: "请填写UID" }],
            validateTrigger: ["blur"],
          },
        ]
      };
    },
  },
  created(){
  },
  mounted() {
    
  },
  filters:{

  },
  methods: {
    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          this.$api.post(`/v2/guildManage/add`, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("添加成功");
                this.$emit("handleOk");
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
