const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: '公会名称',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: '排序',
    key: 'sort',
    dataIndex: 'sort',
    sorter: (a, b) => a.sort - b.sort,

  },
  {
      title: "操作",
      key: "action",
      width: '100px',
      scopedSlots: { customRender: "action" },
  },
];

export default columns;
